import React from 'react'
import Layout from '../../components/help/Layout/Layout'
import Seo from '../../components/help/Seo'
import Header from '../../components/ui/Header/Header'
import HeaderFixed from './../../components/ui/HeaderFixed/HeaderFixed'

import Contacts from '../../components/pages/Contacts/Contacts'
import PageNavigation from '../../components/ui/PageNavigation/PageNavigation'
import { getHrefToHome } from '../../utils/getHrefToHome'
import { useStaticQuery, graphql } from 'gatsby'

const ContactsPage = () => {
    const lang = 'en'

    const hrefHome = getHrefToHome(lang)

    const data = useStaticQuery(graphql`
        {
            title: datoCmsRenshinPageContact(title: { eq: "Title" }, locale: { eq: "en" }) {
                value
            }

            titleForm: datoCmsRenshinPageContact(title: { eq: "Title form" }, locale: { eq: "en" }) {
                value
            }

            blockTitleOne: datoCmsRenshinPageContact(title: { eq: "Block 1: Title" }, locale: { eq: "en" }) {
                value
            }

            blockTextOne: datoCmsRenshinPageContact(title: { eq: "Block 1: Text" }, locale: { eq: "en" }) {
                value
            }

            blockTitleTwo: datoCmsRenshinPageContact(title: { eq: "Block 2: Title" }, locale: { eq: "en" }) {
                value
            }

            blockTextTwo: datoCmsRenshinPageContact(title: { eq: "Block 2: Text" }, locale: { eq: "en" }) {
                value
            }

            thanksTitle: datoCmsRenshinPageContact(title: { eq: "Thanks: Title" }, locale: { eq: "en" }) {
                value
            }

            thanksText: datoCmsRenshinPageContact(title: { eq: "Thanks: Text" }, locale: { eq: "en" }) {
                value
            }

            social: allDatoCmsRenshinSocial(filter: { locale: { eq: "en" } }) {
                nodes {
                    title
                    url
                }
            }

            seo: datoCmsRenshinSeo(pageName: { eq: "Get in touch" }, locale: { eq: "en" }) {
                titleH1
                crumbsName
                bgDesktop {
                    url
                }
                footerLinks {
                    title
                    url
                }
                seo {
                    title
                    description
                }
            }

            main: datoCmsRenshinSeo(pageName: { eq: "Main" }, locale: { eq: "en" }) {
                crumbsName
            }
        }
    `)

    const breadCrumbs = [
        { name: `${data.main.crumbsName}`, href: `${hrefHome}`, current: false },
        { name: `${data.seo.crumbsName}`, current: true }
    ]

    return (
        <Layout lang={lang}>
            <Seo title={data.seo?.seo?.title} description={data.seo?.seo?.description} />

            <Header lang={lang} />
            <HeaderFixed lang={lang} />
            <Contacts crumbs={breadCrumbs} data={data} lang={lang} />
            <PageNavigation links={data.seo.footerLinks} lang={lang} />
        </Layout>
    )
}

export default ContactsPage
